<template>
  <section class="box-height" style="margin-bottom: 70px">
    <!-- 表单 -->
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-col :span="24">
        <p class="DialogTips tip">
          <i class="el-icon-warning"></i
          >制作模板过程中如遇问题或需要帮助时请联系管理员
        </p>
      </el-col>
      <el-row :gutter="24" style="position: static">
        <el-col :span="24">
          <el-button size="mini" class="mgl10" @click="addNew">
            <i class="el-icon-plus"></i> 增加
          </el-button>
          <a :href="downLoadUrl">
            <el-button size="mini" style="margin-right: 10px; margin-left: 10px"
              >下载对照表
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom-start"
                popper-class="quickPipeline"
              >
                <div slot="content" class="popoverCss">
                  <div class="title">对照表说明</div>
                  <div class="content">
                    对照表：简历、客户、项目各字段对应在系统中的字符串
                    <br />作用：将对应字符串配置于背调报告、推荐报告中，在使用报告时可直接获取相关数据
                  </div>
                </div>
                <i
                  class="iconfont icon icon-wenhao-xiangsu"
                  style="font-size: 12px; margin-left: 4px"
                ></i>
              </el-tooltip>
            </el-button>
          </a>
          <el-button size="mini" style="margin-right: 10px" @click="onpreview"
            >查看操作指导</el-button
          >
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination
        ></el-col>
        <el-col :span="24">
          <el-table
            :header-row-style="{ height: '36px' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="ListData"
            class="tabBorder"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="name"
              label="报告名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="type"
              label="模板文件类型"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creatorUserName"
              label="创建人"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creationTime"
              label="创建时间"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="150"
            >
              <template slot-scope="scope">
                <span @click="handleEdit(scope.row.id)" class="tabHref"
                  >编辑</span
                >
                <el-divider direction="vertical"></el-divider>
                <span
                  @click="handleDel(scope.row.id)"
                  class="tabHref"
                  v-has="'setting:wordtemp:del'"
                  >删除</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total, prev, pager, next, sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>

    <!-- 弹框 -->
    <!-- modal-append-to-body -->
    <!-- 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 -->
    <!-- close-on-click-modal -->
    <!-- 是否可以通过点击 modal 关闭 Dialog -->
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="130px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="模板文件类型：" prop="type">
              <el-select
                filterable
                v-model="createDate.type"
                placeholder="请选择模板文件类型"
                style="width: 360px"
              >
                <el-option value="背景调查报告">背景调查报告</el-option>
                <el-option value="候选人推荐报告">候选人推荐报告</el-option>
                <el-option value="人才推荐协议">人才推荐协议</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="模板报告名称：" prop="name">
              <el-input
                v-model.trim="createDate.name"
                style="width: 360px"
                maxlength="20"
                placeholder="请不要输入超过20个字"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="21">
            <el-form-item
              label="上传单个文件："
              prop="filename"
              class="is-required"
            >
              <el-upload
                ref="upload"
                class="upload-demo"
                drag
                :headers="myHeaders"
                :action="actionUrl"
                :on-success="handleInfoSuccess"
                :on-change="handleInfoChange"
                :file-list="fileInfoList"
                :on-remove="handleRemove"
                :auto-upload="false"
                :before-upload="beforeUpload"
                accept=".docx"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text" style="line-height: 1">
                  将文件拖到此处，或
                  <span class="tabHref">点击上传</span>
                </div>
                <div
                  slot="tip"
                  style="
                    margin-top: -5px;
                    font-size: 12px;
                    line-height: 1.5;
                    color: #606266;
                  "
                >
                  支持扩展名： .docx
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetForm('createDate')"
          >取 消</el-button
        >
        <el-button
          size="mini"
          :loading="saveloading"
          type="primary"
          @click="save('createDate')"
          :disabled="!canClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import Cookies from "js-cookie";
import {
  getTemplatereportList,
  addOrEditTemplatereport,
  deleteTemplatereport,
  getTemplatereportById,
} from "../../api/api";
// import * as api from "../../libs/axios";
export default {
  created() {
    this.loadList();
    this.actionUrl = process.env.VUE_APP_baseUrl + "/api/file/uploadOne";
  },
  data() {
    return {
      actionUrl: null,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      downLoadUrl:
        process.env.VUE_APP_baseUrl + "/template/模板字段对照表.xlsx",
      ListData: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      listLoading: false,
      dialogVisible: false,
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写模板报告名称",
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
            message: "请选择模板文件类型",
          },
        ],
      },
      createDate: {
        id: 0,
        name: null,
        filepath: null,
        version: 0,
        type: null,
        filename: null,
      },
      titName: "",
      fileInfoList: [],
      canClick: false,
      saveloading: false,
      upload_flag: false,
    };
  },
  methods: {
    onpreview() {
      window.open(
        "https://view.officeapps.live.com/op/view.aspx?src=http://yd.casearching.com/pics/templateReport/%E6%A8%A1%E6%9D%BF%E5%88%B6%E4%BD%9C_%E6%93%8D%E4%BD%9C%E6%8C%87%E5%AF%BC%E6%96%87%E6%A1%A3.docx"
      );
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      getTemplatereportList({
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        } else {
          this.listLoading = false;
        }
      });
    },

    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.fileInfoList = [];
      this.dialogVisible = false;
    },
    handleEdit: function (id) {
      this.dialogVisible = true;
      this.upload_flag = false;
      this.titName = "编辑模板报告";
      getTemplatereportById({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.createDate.name = res.result.name;
          this.createDate.filepath = res.result.filepath;
          this.createDate.filename = res.result.filename;
          this.createDate.type = res.result.type;
          this.createDate.id = id;
          this.createDate.version = res.result.version;
          this.fileInfoList.push({
            name: res.result.filename,
            filepath: res.result.filepath,
          });
          this.canClick = true;
        }
      });
    },
    addNew: function () {
      this.createDate.id = 0;
      this.createDate.version = 0;
      this.dialogVisible = true;
      this.titName = "新增模板报告";
      this.canClick = false;
    },
    handleDel: function (id) {
      this.$confirm("请确认是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTemplatereport({ id: id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.upload_flag) {
            this.$refs.upload.submit();
          } else {
            this.saveInfo();
          }
        } else {
          return false;
        }
      });
    },
    saveInfo: function () {
      this.saveloading = true;
      addOrEditTemplatereport(this.createDate).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.resetForm("createDate");
          this.loadList();
        } else {
          this.upload_flag = false;
        }
        this.saveloading = false;
      });
    },
    beforeUpload: function (file) {
      const fileType = file.name.split(".")[file.name.split(".").length - 1];
      if (fileType != "docx") {
        this.$message({
          type: "error",
          message: "请选择扩展名为 .docx的文件",
        });
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt10M;
    },
    handleInfoSuccess: function (res, file, fileList) {
      this.createDate.filename = file.name;
      this.createDate.filepath = res.result;
      this.saveInfo();
    },
    handleRemove(file, fileList) {
      this.fileInfoList = [];
      this.canClick = false;
    },
    handleInfoChange: function (file, fileList) {
      if (file.response && !file.response.result.isSuccess) return;
      this.fileInfoList = [];
      this.fileInfoList.push(file);
      this.canClick = true;
      this.upload_flag = true;
    },
  },
};
</script>
<style lang='less'>
.quickPipeline {
  width: 310px;
}
.popoverCss {
  font-size: 12px;
  .title {
    margin-bottom: 12px;
  }
  .content {
    color: #cccccc;
    line-height: 22px;
  }
}
</style>
<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
}
.tip {
  margin: 0 15px 15px 10px;
}
</style>



